import { RouteName } from "@/router/routeName";
import type { MoonRouteMeta } from "@/models/moonRouteMeta";
import { UserPermission } from "@/models/userPermission";

interface Dictionary<T> {
  [Key: string]: T;
}

export default (routeName: RouteName): MoonRouteMeta => {
  const mapping: Dictionary<MoonRouteMeta> = {
    [RouteName.AUTH]: {
      requiresAuth: false,
      requiredPermissions: [],
    },
    [RouteName.LOGIN]: {
      requiresAuth: false,
      requiredPermissions: [],
    },
    [RouteName.PUBLIC_SHOP]: {
      requiresAuth: false,
      requiredPermissions: [],
    },
    [RouteName.USER_ONBOARD]: {
      requiresAuth: false,
      requiredPermissions: [],
    },
    [RouteName.PASSWORD_RESET_REQUEST]: {
      requiresAuth: false,
      requiredPermissions: [],
    },
    [RouteName.PASSWORD_RESET]: {
      requiresAuth: false,
      requiredPermissions: [],
    },
    [RouteName.DASHBOARD]: {
      requiredPermissions: [],
    },
    [RouteName.ORGANISATIONS]: {
      requiredPermissions: [UserPermission.orgList],
    },
    [RouteName.ORGANISATION_LIST]: {
      requiredPermissions: [UserPermission.orgList],
    },
    [RouteName.ORGANISATION_NEW]: {
      requiredPermissions: [UserPermission.orgList, UserPermission.orgCreate],
    },
    [RouteName.ORGANISATION_EDIT]: {
      requiredPermissions: [UserPermission.orgList, UserPermission.orgEdit],
    },
    [RouteName.SCENARIO_TREES]: {
      requiredPermissions: [UserPermission.orgList, UserPermission.orgEdit],
    },
    [RouteName.SUITE_STATE]: {
      requiredPermissions: [UserPermission.orgList, UserPermission.orgEdit],
    },
    [RouteName.SUITE_STATE_LIST]: {
      requiredPermissions: [UserPermission.orgList, UserPermission.orgEdit],
    },
    [RouteName.SUITE_STATE_UPDATE]: {
      requiredPermissions: [UserPermission.orgList, UserPermission.orgEdit],
    },
    [RouteName.USER]: {
      requiredPermissions: [UserPermission.userListUnder],
    },
    [RouteName.USER_SUBMIT_SCORE]: {
      requiredPermissions: [UserPermission.superAdmin],
    },
    [RouteName.AUDIT]: {
      requiredPermissions: [UserPermission.superAdmin],
    },
    [RouteName.ANALYTICS]: {
      requiredPermissions: [UserPermission.superAdmin],
    },
    [RouteName.USERS]: {
      requiredPermissions: [UserPermission.userListUnder],
    },
    [RouteName.ADD_USERS]: {
      requiredPermissions: [UserPermission.userListUnder],
    },
    [RouteName.TRENDS_ANALYTICS]: {
      requiredPermissions: [
        UserPermission.scoreList,
        UserPermission.userListUnder,
      ],
    },
    [RouteName.LEARNING_MATERIALS]: {
      requiredPermissions: [],
    },
    [RouteName.REPORT_GENERATOR]: {
      requiredPermissions: [
        UserPermission.scoreList,
        UserPermission.userListUnder,
      ],
    },
    [RouteName.HEADSET_LOGIN]: {
      title: "Headset login",
      requiredPermissions: [],
    },
    [RouteName.MY_PROFILE]: {
      requiredPermissions: [],
    },
    [RouteName.MOONBOARDING]: {
      requiredPermissions: [],
    },
    [RouteName.MY_LEARNING]: {
      requiredPermissions: [],
    },
    [RouteName.LEADERBOARD]: {
      requiredPermissions: [UserPermission.userListUnder],
    },
    [RouteName.ORGANISATION_LOGIN]: {
      requiresAuth: false,
      requiredPermissions: [],
    },
    [RouteName.PLATFORM_ISSUES]: {
      requiredPermissions: [UserPermission.superAdmin],
    },
    // We only allow 'authorised' users to get 404 pages. Unauthorised users who are hitting a 404 route
    // will get re-directed to the login page instead. Provides a soft avoidance of route enumeration
    // (JS bundles can be inspected to work out what routes are doing)
    [RouteName.NOT_FOUND]: {
      requiresAuth: true,
      requiredPermissions: [],
    },
  };

  return mapping[routeName];
};
